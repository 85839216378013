// src/contexts/AudioPlayerContext.js
import React, { createContext, useContext, useState } from "react";

import SoundControl from "./SoundControl";

const AudioPlayerContext = createContext({
  setAudio: (asset: Queries.SanityAsset) => {},
  pauseAudio: () => {},
  playAudio: () => {},
});

export const useAudioPlayer = () => useContext(AudioPlayerContext);

export const AudioPlayerProvider = ({ children }) => {
  const { AudioPlayer, pauseAudio, playAudio, setAudio } = SoundControl();

  return (
    <AudioPlayerContext.Provider value={{ setAudio, pauseAudio, playAudio }}>
      {children}
      {AudioPlayer}
    </AudioPlayerContext.Provider>
  );
};
