import { AdvancedVideo } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
// import { quality } from "@cloudinary/url-gen/actions/delivery";
// import { source } from "@cloudinary/url-gen/actions/overlay";
// import { fill } from "@cloudinary/url-gen/actions/resize";
// import { audioCodec, bitRate } from "@cloudinary/url-gen/actions/transcode";
// import { trim, volume } from "@cloudinary/url-gen/actions/videoEdit";
// import { audio } from "@cloudinary/url-gen/qualifiers/source";
// import { position } from "@cloudinary/url-gen/qualifiers/timeline";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { ExtendedSanityAssetOrAssetFile } from "../Tabs/Tabs";
import { speaker_svg, speakerOff_svg, zoomIn_svg, zoomOut_svg } from "./sound-control-elements";

interface SoundControlReturnType {
  AudioPlayer: JSX.Element;
  playAudio: () => void;
  pauseAudio: () => void;
  setAudio: (asset: Queries.SanityAsset) => void;
}

const SoundControl = (_asset?: ExtendedSanityAssetOrAssetFile): SoundControlReturnType => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const buttonOn = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const buttonOff = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [isZoomApplied, setIsZoomApplied] = useState<boolean>(false);

  const backgroundAudio = "Mahler__Adagietto";

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME || "",
    },
  });
  const playAudio = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const pauseAudio = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const song = cld.video(backgroundAudio);

  const setAudio = (asset: Queries.SanityAsset) => {
    if (asset?.file?.public_id) {
      loadNewAudio(asset.file.public_id);
    }
  };

  function playOn() {
    buttonOff.current.classList.remove("sound-control__off--active");
    buttonOn.current.classList.add("sound-control__on--active");
  }

  function playOff() {
    buttonOff.current.classList.add("sound-control__off--active");
    buttonOn.current.classList.remove("sound-control__on--active");
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("canplaythrough", handleAudioLoaded);
      videoRef.current.addEventListener("play", playOn);
      videoRef.current.addEventListener("pause", playOff);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("canplaythrough", handleAudioLoaded);
        videoRef.current.removeEventListener("play", playOn);
        videoRef.current.removeEventListener("pause", playOff);
      }
    };
  }, []);

  const handleAudioLoaded = () => {
    setAudioLoaded(true);
  };

  function loadNewAudio(public_id: string) {
    if (!videoRef?.current) return;
    if (!public_id) return;
    if (public_id === backgroundAudio) return;
    const nextAudio = cld.video(public_id);
    if (nextAudio.toURL() === videoRef.current.src) return;

    setAudioLoaded(false);
    videoRef.current.pause();
    videoRef.current.src = nextAudio.toURL();
    videoRef.current.play();
  }

  function handleZoom() {
    if (isZoomApplied) {
      // poner en pantalla completa el navegador
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsZoomApplied(false);
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
        setIsZoomApplied(false);
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari y Opera
        document.webkitExitFullscreen();
        setIsZoomApplied(false);
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
        setIsZoomApplied(false);
      }
    } else {
      // salir de pantalla completa
      const element = document.documentElement; // El documento completo

      if (element.requestFullscreen) {
        element.requestFullscreen();
        setIsZoomApplied(true);
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
        setIsZoomApplied(true);
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari y Opera
        element.webkitRequestFullscreen();
        setIsZoomApplied(true);
      } else if (element.msRequestFullscreen) {
        // IE/Edge
        element.msRequestFullscreen();
        setIsZoomApplied(true);
      } else {
        alert("Su navegador no soporta la funcionalidad de pantalla completa");
      }
    }
  }

  // return <div className={`m-sound-control`}>{speaker_svg}</div>;
  return {
    AudioPlayer: (
      <div className={"m-sound-control"}>
        <a
          className={"button-clean sound-control__ticket"}
          target={"_blank"}
          href={"https://tickets.caemz.com/"}
          rel="noreferrer"
        >
          Tickets
        </a>
        <div className="m-sound-control__song">
          <AdvancedVideo cldVid={song} controls autoPlay={false} muted={false} loop={true} innerRef={videoRef} />
        </div>
        <div className="m-sound-control__speaker">
          <button ref={buttonOn} className={`sound-control__on`} onClick={pauseAudio}>
            {speaker_svg}
          </button>
          <button
            ref={buttonOff}
            className={`sound-control__off sound-control__off--active`}
            onClick={playAudio}
            disabled={!audioLoaded}
          >
            {speakerOff_svg}
          </button>
          <button
            className={`sound-control__zoom`}
            onClick={() => {
              handleZoom();
            }}
          >
            {isZoomApplied ? zoomOut_svg : zoomIn_svg}
          </button>
        </div>
      </div>
    ),
    playAudio,
    pauseAudio,
    setAudio,
  };
};

export default SoundControl;
