import "vanilla-cookieconsent/dist/cookieconsent.css";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import "./src/styles/index.scss";




import { GatsbyBrowser } from "gatsby";
import * as React from "react";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import { ConfigCookieConsent } from "./src/modules/CookieConsent/CookieConsent";
import { AudioPlayerProvider } from "./src/modules/SoundControl/AudioPlayerContext";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <AudioPlayerProvider>{element}</AudioPlayerProvider>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  useEffect(() => {
    CookieConsent.run(ConfigCookieConsent);
    document.documentElement.classList.add("cc--darkmode");
  }, []);
  return element;
};
