import * as React from "react";
// Remember that all const are suffix _svg

const speaker_svg = (
  <svg
    width="67"
    height="68"
    className={"speaker_svg"}
    viewBox="0 0 67 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.4866 49.976C42.6447 50.3443 42.0923 51.1861 42.0923 52.0937C42.0923 52.8829 42.4869 53.6064 43.1446 54.0273C43.526 54.2772 43.9601 54.4087 44.4073 54.4087C44.723 54.4087 45.0387 54.343 45.3412 54.2114C53.4306 50.6337 58.6656 42.6233 58.6656 33.7973C58.6656 24.9713 53.4306 16.9609 45.3412 13.3832C44.6309 13.0675 43.8022 13.1332 43.1577 13.5541C42.5 13.9882 42.1054 14.7116 42.1054 15.5009C42.1054 16.4084 42.6447 17.2371 43.4997 17.6186C49.9054 20.4597 54.0488 26.8128 54.0488 33.7973C54.0488 40.7818 49.9054 47.1349 43.4997 49.976H43.4866Z"
      fill="white"
    />
    <path
      d="M49.3006 33.8236C49.3006 38.5325 46.3017 42.5311 42.1057 44.0175V23.6296C46.3017 25.116 49.3006 29.1278 49.3006 33.8367V33.8236Z"
      fill="white"
    />
    <path
      d="M34.0552 9.437L16.89 22.5773H8.81375C6.99858 22.5773 5.52539 24.0505 5.52539 25.8656V42.1101C5.52539 43.9253 6.99858 45.3985 8.81375 45.3985H16.89L34.0552 58.5388C35.0812 59.328 36.5675 58.5914 36.5675 57.3024V10.6997C36.5675 9.39753 35.0812 8.67409 34.0552 9.4633V9.437Z"
      fill="white"
    />
  </svg>
);

const speakerOff_svg = (
  <svg
    className={"speaker_svg speaker_svg--off"}
    width="67"
    height="68"
    viewBox="0 0 67 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.063 34.4927C58.063 38.1121 57.1156 41.5857 55.3909 44.61L52.221 41.44C53.2412 39.3024 53.7877 36.934 53.7877 34.4806C53.7877 28.0192 49.974 22.165 44.047 19.5294C43.2697 19.1894 42.7717 18.412 42.7717 17.5861C42.7717 16.8574 43.124 16.1894 43.7434 15.7886C44.3507 15.3999 45.1037 15.3271 45.7717 15.6307C53.2412 18.9343 58.0751 26.3309 58.0751 34.4806L58.063 34.4927Z"
      fill="white"
    />
    <path
      d="M49.4153 34.517C49.4153 35.7437 49.1967 36.9218 48.7838 38.0028L42.7717 31.9907V25.092C46.6462 26.4645 49.4153 30.1689 49.4153 34.517Z"
      fill="white"
    />
    <path
      d="M37.6585 13.1407V26.8773L28.2214 17.4402L35.3387 11.9869C36.2861 11.2703 37.6585 11.9383 37.6585 13.1407Z"
      fill="white"
    />
    <path
      d="M14.5822 24.1323L37.6588 47.221V56.1723C37.6588 57.3747 36.2863 58.0548 35.339 57.3261L19.4769 45.1927H12.0195C10.3435 45.1927 8.98315 43.8324 8.98315 42.1563V27.1687C8.98315 25.4926 10.3313 24.1323 12.0195 24.1323H14.5701H14.5822Z"
      fill="white"
    />
    <path
      d="M58.0633 59.8522C57.4439 59.8522 56.8366 59.6215 56.3629 59.1478L10.4407 13.2255C9.50546 12.2903 9.50546 10.7721 10.4407 9.8369C11.3759 8.9017 12.8941 8.9017 13.8293 9.8369L59.7516 55.7592C60.6868 56.6944 60.6868 58.2126 59.7516 59.1478C59.2779 59.6215 58.6706 59.8522 58.0512 59.8522H58.0633Z"
      fill="white"
    />
  </svg>
);

const zoomIn_svg = (
  <svg className={"zoom_svg zoomIn_svg"} xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 512 512">
    <path fill={"#fff"}
      d="M344 0L488 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512L24 512c-13.3 0-24-10.7-24-24L0 344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/>
  </svg>
);


const zoomOut_svg = (<svg className={"zoom_svg zoomOut_svg"} xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
  <path fill={"#fff"}
    d="M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8l-144 0c-13.3 0-24-10.7-24-24l0-144c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272l144 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z"/>
</svg>)

export {speaker_svg, speakerOff_svg, zoomIn_svg, zoomOut_svg};
